import React from "react";
import { RiCameraLensFill, RiAccountCircleFill } from "react-icons/ri";
import "./Message.css";

const Message = ({ message: { text, user }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();
  user = user.trim().toLowerCase();

  if (user === trimmedName) {
    isSentByCurrentUser = true;
  }

  return isSentByCurrentUser ? (
    <div className="messageContainer justifyEnd">
      <p className="sentText pr-10">
        <RiAccountCircleFill style={{ fontSize: 20, color: "#cccccc" }} />
        {/* {trimmedName} */}
      </p>
      <div className="messageBox backgroundBlue">
        <p className="messageText colorWhite">{text}</p>
      </div>
    </div>
  ) : (
    <div className="messageContainer justifyStart">
      <div className="messageBox backgroundLight">
        <p className="messageText colorDark">{text}</p>
      </div>
      <p className="sentText pl-10 ">
        {/* {user} */}
        <RiCameraLensFill
          className="riSpin"
          style={{ fontSize: 20, color: "#cccccc" }}
        />
      </p>
    </div>
  );
};

export default Message;
