import React from "react";
import { RiArrowRightFill } from "react-icons/ri";
import "./Input.css";

const Input = ({ setMessage, sendMessage, message }) => (
  <form className="form">
    <input
      className="input"
      type="text"
      placeholder="Ask something..."
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={(event) =>
        event.key === "Enter" ? sendMessage(event) : null
      }
    />
    <button className="sendButton" onClick={(e) => sendMessage(e)}>
      <RiArrowRightFill style={{ fontSize: 40, color: "#fff" }} />
    </button>
  </form>
);

export default Input;
