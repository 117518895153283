import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Join.css";
import "./BlockAnimation.css";

export default function SignIn() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  /* room is changed to password */

  return (
    <div className="joinOuterContainer">
      <div className="area" style={{ position: "absolute" }}>
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="joinInnerContainer">
        <h1 className="heading">STACK DART</h1>
        <div>
          <input
            placeholder="Name"
            className="joinInput"
            type="text"
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div>
          <input
            placeholder="Password"
            className="joinInput mt-20"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <Link
          onClick={(e) =>
            !name || !password || password !== "admin"
              ? e.preventDefault()
              : null
          }
          to={`/chat?name=${name}`}
          /* to={`/chat?name=${name}&room=${room}`}  */
        >
          <button className={"button mt-20"} type="submit">
            Sign In
          </button>
        </Link>
      </div>
    </div>
  );
}
