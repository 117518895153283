import React, { useState, useEffect } from "react";
import queryString from "query-string";

//import TextContainer from "../TextContainer/TextContainer";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";

import ApisCommon from "../../utils/ApisCommon";
import { ApiEndPoints } from "../../utils/EndPoints";

import "./Chat.css";

const Chat = ({ location }) => {
  const apis = new ApisCommon();
  const [name, setName] = useState("");
  //const [users, setUsers] = useState("");
  const [processIndicator, setProcessIndicator] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      user: "ai",
      text: "I am an AI created by OpenAI. How can I help you today?",
    },
  ]);

  useEffect(() => {
    let { name } = queryString.parse(location.search);
    if (name === "ai") {
      name = "ai_2";
    }
    if (name === "") {
      name = "rahul";
    }
    setName(name);
    //setUsers(users);
  }, [location.search]);

  const sendMessage = (event) => {
    event.preventDefault();
    if ("" + message.trim() !== "") {
      const qid = `chatGPT${+new Date()}`;
      setProcessIndicator("Typing...");
      setMessages((messages) => [...messages, { user: name, text: message }]);
      setMessage("");
      apis.getCommonData(
        ApiEndPoints.aichat,
        {
          question: message,
          qid: window.btoa(
            window.btoa(qid).split("").reverse().join("").replace("=", "")
          ),
        },
        (data) => {
          const answer = data.data.data.choices[0].text.trim();
          setMessages((messages) => [
            ...messages,
            { user: "ai", text: answer },
          ]);
          setProcessIndicator("");
        }
      );
    }
  };

  return (
    <div className="outerContainer">
      <div className="container">
        <InfoBar indicator={processIndicator} messages={messages} />
        <Messages messages={messages} name={name} />
        <Input
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
        />
      </div>
      {/* <TextContainer users={users} /> */}
    </div>
  );
};

export default Chat;
