import Config from "../config";
//import { ApiEndPoints } from "./EndPoints";
import axios from "axios";
const baseUrl = Config.apiBaseUrl;

class ApisCommon {
  headers = {
    Authorization: Config.authorization,
    "Content-Type": Config.contenttype,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "POST, PUT, GET",
    "Access-Control-Max-Age": 86400,
  };

  exportCsvFileUrls = {};

  constructor() {
    this.initLoader();
  }

  initLoader() {
    axios.interceptors.request.use(
      function (config) {
        document.body.classList.add("loading-indicator");
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        document.body.classList.remove("loading-indicator");
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  postRequest = (payload) => {
    return axios
      .post(payload.url, payload.data, { headers: this.headers })
      .catch((error) => {
        return { error: error };
      })
      .finally((res) => {
        //console.log(res);
      });
  };

  getRequest = (payload) => {
    return axios
      .get(payload.url, { headers: this.headers })
      .catch((error) => {
        return { error: error };
      })
      .finally((res) => {
        //console.log(res);
      });
  };

  actionCommonRequest = (data, action_type, method, stringify = false) => {
    if (stringify === true) {
      data = JSON.stringify(data);
    }
    const payload = {
      url: baseUrl + action_type,
      data: data,
    };
    if (method === "POST") {
      return this.postRequest(payload);
    } else if (method === "GET") {
      return this.getRequest(payload);
    } else {
      alert("Please pass argument method type: get or post");
    }
  };

  // Common get data
  getCommonData = (endPoint, payload, callback) => {
    let method = null;
    payload === null ? (method = "GET") : (method = "POST");
    this.actionCommonRequest(payload, endPoint, method, true).then((data) => {
      callback(data);
    });
  };

  getRandomNumber() {
    return (
      new Date().getTime() + Math.floor(Math.random() * (99999999 - 1 + 1)) + 1
    );
  }
}
export default ApisCommon;
