export const ApiEndPoints = {
  aichat: "find/aichat",
  years: "find/years",
  specialdistrictcategory: "find/specialdistrictcategory",
  allstates: "find/allstates",
  alldistricts: "find/alldistricts",
  allmasterindicator: "find/allmasterindicator",
  masterindicatordetails: "chunk/masterindicatordetails",
  statewisedistricts: "find/statewisedistricts",
  districtSchoolTeacherData: "find/districtschoolteacherdata",
  districtDemographicData: "find/districtdemographicdata",
  staticreportdata: "find/staticreportdata",
};
