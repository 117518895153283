import React from "react";
import {
  RiCheckboxBlankCircleFill,
  RiCloseFill,
  RiDownloadFill,
} from "react-icons/ri";

import "./InfoBar.css";

const InfoBar = ({ indicator, messages }) => {
  const downloadChat = () => {
    const rows = [["User", "Chat Text"]];
    messages.forEach((m) => {
      rows.push([m.user, m.text]);
    });
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "chat_transcript.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };
  return (
    <div className="infoBar">
      <div className="leftInnerContainer">
        <RiCheckboxBlankCircleFill
          style={{ color: "#29ff3e", marginRight: "10px" }}
        />
        <p>{indicator !== "" ? indicator : "ChatGPT Bot"}</p>
      </div>
      <div className="rightInnerContainer">
        {messages.length > 10 && (
          <button
            style={{
              background: "#249D7A",
              color: "#fff",
              border: "1px solid #fff",
              padding: "4px",
              fontWeight: "bold",
              borderRadius: "10px",
              marginRight: "30px",
              cursor: "pointer",
            }}
            onClick={downloadChat}
          >
            <RiDownloadFill style={{ verticalAlign: "bottom" }} />
            Download Chat
          </button>
        )}

        <a href="/">
          <RiCloseFill style={{ color: "#fff", fontSize: "20px" }} />
        </a>
      </div>
    </div>
  );
};

export default InfoBar;
